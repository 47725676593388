import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
// import Header from '../containers/layout/header/header-three'
// import Header from '../containers/layout/header/header-four'
import Header from '../containers/layout/header/header-three/indexSub'

import Footer from '../containers/layout/footer/footer-one'
// import HeroArea from '../containers/index-services/hero-area'
//import HeroArea from '../containers/index-cybersecurity/hero-area'
//  import HeroArea from '../containers/index-appointment/hero-area'
import PageHeader from '../components/pageheader'
import FeaturesArea from '../containers/index-services/features-area'
import AboutArea from '../containers/index-services/about-area'
import ServicesArea from '../containers/index-services/services-area'
import GradationArea from '../containers/index-services/gradation-area'
import CtaArea from '../containers/global/cta-area/section-one'
import CaseStudyArea from '../containers/index-services/case-study-area'
import BlogArea from '../containers/index-services/blog-area'
import ContactArea from '../containers/global/contact-area/contact-four'
import ClientsArea from '../containers/global/clients-area'

// import Features from '../containers/elements/box-image/section-one'
// import Features from '../containers/elements/box-image/section-seven'
import Features from '../containers/elements/box-image/section-four'


import AboutServiceWrap from '../containers/index-infotechno/about-service-wrap'
import ServicesAreaFeatures from '../containers/index-infotechno/services-area'
import { Container, Row, Col } from "react-bootstrap"
import TermsConditions from '../containers/index-services/policies/terms-conditions'

import styled from 'styled-components';
import {device} from '../theme'

// import AboutArea from '../containers/index-infotechno/about-area'


const PoliciesWrapper = styled.section`
    padding-top: 100px;
    padding-bottom: 90px;
    background-color: #F8F8F8;
    min-height:60vh;
    
    ul{
        padding-left: 1.5rem;    
        margin-botton: 1rem;
        list-style: disc;
    }

    ul li{

    }

    h5{
        margin-top: 1rem;
    }

    p{
        margin: 0;
        margin-top: 0.3rem;
    }

    blockquote{
        margin: 54px 65px 44px 70px;
        padding: 0 0 0 20px;
        font-size: 18px;
        line-height: 1.78;
        font-weight: 600;
        border-left: 4px solid #eee;
        border-left-color: #d2a98e;
        position: relative;
        color: #333;
        @media ${device.small}{
            font-size: 16px;
            margin: 50px 70px 44px;
        }
        @media ${device.xsmall}{
            margin: 40px 30px;
        }
    }

    @media ${device.medium}{
        padding-top: 80px;
        padding-bottom: 70px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 50px;
    }
`;


const IndexServices = ({ location, pageContext }) => (
  <Layout location={location}>
    <SEO />
    <Header transparent={false} />
    <main className="site-wrapper-reveal">
      <PageHeader pageContext={pageContext} location={
        { pathname: "checkout" }
      } title="Checkout" />
      <PoliciesWrapper>
        <Container>
          <Row className="d-flex align-items-center justify-content-center">
            <Col md={6} className="text-center">
              <h4 className="mb-3">¡Gracias por tu pedido!</h4>
              <p>Es un placer informarte que hemos recibido tu pago con éxito. Queremos agradecerte por confiar en nuestros servicios y por elegirnos como tu proveedor. Un correo será enviado al email suministrado con la información de tu producto.</p>
            </Col>
          </Row>
        </Container>

      </PoliciesWrapper>
    </main>

  </Layout>
)



export default IndexServices
